<template>
    <header>
      <div class="main clearfix">
        <div class="logo-content" >
            <router-link to="/home" class="logo">
                <div class="logo-img"></div>
            </router-link>
        </div>
        <div class="controls">
          {{lang}}
          <div class="btn liveChat" @click="showLiveChat()" data-testid="liveChat"></div>
          <div class="btn langCommand" @click="drawer = true"></div>
        </div>
        <el-drawer
          title="lang"
          :visible.sync="drawer"
          :with-header="false"
          :size="280"
          :modal="false"
          :direction="direction"
          onClose="handleClose"
        >
          <div class="lang-select">
            <div v-for="item in languages" :key="item.value" :class="{ active: lang == item.value }" class="lang-select-item" @click="langCommand(item)">
              <!-- <img :src="item.icon" alt="" /> -->
              <span>{{ item.label }}</span>
            </div>
          </div>
        </el-drawer>
      </div>
    </header>
  </template>
  
  <script>
  import headerMixins from '@/mixins/component/template/header';
  
  export default {
    name: 'vHeader',
    mixins: [headerMixins],
    data() {
      return {
        drawer: false,
      }
    },
    computed: {
      direction() {
        return this.lang === 'ar' ? 'ltr' : 'rtl'
      }
    },
    methods: {
      langCommand(command) {
        this.language = command;
        //see if language is changed in the landing page
        this.$store.commit('common/setIsChangedLangAtLandingPage', true);
      }
    }
  };
  </script>
  
  <style lang="scss" scoped>
  @import '@/assets/css/layout/PUGHeader.scss';
  </style>
  